<template>
    <div class="page1">
        <EButton type="primary" @click="dialogShow">增加</EButton>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="editDialog(scope.row)">
                            修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row.waterPriceConfigId)">
                            删除
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
                 width="40%"
                 @handleClick="saveData"
        :disabled="saveDisabled">

            <el-form ref="form" :model="dialogForm" label-position="right" label-width="100px"
                     :rules="formRules">
                <el-form-item label='面积区间' required>
                    <el-row >
                        <el-col :span="10">
                            <el-form-item prop="minArea">
                                <el-input v-model="dialogForm.minArea" placeholder="最小（包含）"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" class="tc">-</el-col>
                        <el-col :span="10">
                            <el-form-item prop="maxArea">
                                <el-input v-model="dialogForm.maxArea" placeholder="最大（不包含）"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label='收费标准' prop="price">
                    <el-input v-model="dialogForm.price"></el-input>
                </el-form-item>
            </el-form>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  export default {
    name: 'Login',
    mixins:[otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '最小面积（平方米）',
            prop: 'minArea'
          },
          {
            label: '最大面积（平方米）',
            prop: 'maxArea'
          },
          {
            label: '收费标准（每月）',
            prop: 'price'
          },
          {
            label: '新建人',
            prop: 'createUserCn'
          },
          {
            label: '新建时间',
            prop: 'createTime'
          },
          {
            label: '修改时间',
            prop: 'updateTime'
          },
        ],
        tableData: [],
        // dialogFormColumns: [
        //   {
        //     title: '电费名称',
        //     type: 'text',
        //     property: 'electricPriceName',
        //     placeHolder: '最多可录入20字',
        //     show: true,
        //   },
        //   {
        //     title: '电费单价（元/度）',
        //     type: 'text',
        //     property: 'electricUnitPrice',
        //     placeHolder: '最多可录入20字',
        //     show: true,
        //   },
        //   {
        //     title: '电费附加名称',
        //     type: 'text',
        //     property: 'electricPriceAdditionalName',
        //     placeHolder: '最多可录入20字',
        //     show: true,
        //   },
        //   {
        //     title: '附加费单价（元/度） ',
        //     type: 'text',
        //     property: 'electricPrice',
        //     placeHolder: '最多可录入20字',
        //     show: true,
        //   },
        //   {
        //     type: 'actionBtn',
        //     show: false
        //   }
        // ],
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '150px',
        dialogForm: {
          minArea: '',
          maxArea: '',
          price: '',
        },
        options: [],
        title: '公用水费收费区间配置',
        formRules: {
          minArea: [vxRule(true, '', "change", "面积最小值不能为空"),
            vxRule(true, 'IntPlus', "change", "请输入正整数"),
            vxRule(true, val=>{
              return {result: Number(val)<=this.dialogForm.maxArea, errMsg: "面积最小值不能大于面积最大值"}})],
          maxArea: [vxRule(true, '', "blur", "面积最大值不能为空"),
            vxRule(true, 'IntPlus', "blur", "请输入正整数"),
            vxRule(true, val=>{
              this.$refs.form.validateField('minArea')
              return {result: Number(val)>=Number(this.dialogForm.minArea), errMsg: "面积最大值不能小于面积最小值"}})
          ],
          price: [vxRule(true, '', "blur", "收费标准不能为空"),
            vxRule(true, 'Float2', "blur", "请输入数字，最多两位小数"),],
        },
      }
    },
    watch: {},
    created() {
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      async cmwaterpriceconfigRemove(ids){
        let res = await Http.cmwaterpriceconfigRemove({ids})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      remove(id){
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.cmwaterpriceconfigRemove(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      editDialog(data) {
        this.dialogVisible=true
        this.dialogForm = {
          waterPriceConfigId:data.waterPriceConfigId,
          minArea: data.minArea,
          maxArea: data.maxArea,
          price: data.price,
        }
        this.title='修改公用水费收费区间配置'
      },
      async cmwaterpriceconfigSubmit() {
        this.setDisabled(true)
        let res = await Http.cmwaterpriceconfigSubmit(this.dialogForm)
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      dialogShow() {
        this.dialogVisible = true
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          waterPriceConfigId:'',
          minArea: '',
          maxArea: '',
          price: '',
        }
        this.title='公用水费收费区间配置'
        this.$refs.form.resetFields()
      },
      showDialog() {
        this.dialogVisible = true
      },
      saveData() {
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.cmwaterpriceconfigSubmit()
          } else {
            return false;
          }
        })
      },
      async getData() {
        let res = await Http.cmwaterpriceconfigList()
        if (res.code == 200) {
          this.tableData = res.data
        }
      }
    }
  }
</script>

<style lang="scss">

</style>
